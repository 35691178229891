import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes, { number } from "prop-types";
import { connect } from "react-redux";
import { fetch_api_data } from "../../actions/auth";
import {
  getsifs,
  getsifsuniversal,
  getsifsnotnew,
  markasdone,
  getexternalaccountinfo,
  updateinternaldata,
} from "../../actions/sif";

import "./ClientManager.css";

const ClientManager = ({
  getsifs,
  getsifsuniversal,
  getsifsnotnew,
  fetch_api_data,
  updateinternaldata,
  markasdone,
  getexternalaccountinfo,
  sif: { sifs, clientdbasedata },
  auth: { apidata },
}) => {
  useEffect(() => {
    fetchAll();
    fetch_api_data();
  }, []); // Initial fetch of "NEW" status data

  // local group
  const [sifId, setSifId] = useState(-1);
  const [agencyName, setAgencyName] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [requestedDate, setRequestedDate] = useState("1900-01-01");
  const [requestedSif, setRequestedSif] = useState("0.00");
  const [numberOfPayments, setNumberOfPayments] = useState("0");
  const [status, setStatus] = useState("0");
  const [dueDate, setDueDate] = useState("1900-01-01");
  const [countered, setCountered] = useState(false);
  const [counterOffer, setCounterOffer] = useState("0.00");
  const [counterNumberOfPayments, setCounterNumberOfPayments] = useState("0");
  const [reason, setReason] = useState("");

  // global group
  const [cFileNumber, setCFileNumber] = useState("");
  const [cPortFolio, setCPortfolio] = useState("");
  const [cDatePlaced, setCDatePlaced] = useState("");
  const [cOriginalCreditor, setCOriginalCreditor] = useState("");
  const [cChargeoffDate, setCChargeoffDate] = useState("");
  const [cOriginalBalance, setCOriginalBalance] = useState("");
  const [cCurrentBalance, setCCurrentBalance] = useState("");
  const [cCampaignName, setCCampaignName] = useState("");
  const [cActive, setCActive] = useState("");
  const [cLevel, setCLevel] = useState("");
  const [cAssignedTo, setCAssignedTo] = useState("");
  const [cCampaignFee, setCCampaignFee] = useState("");
  const [cProductType, setCProductType] = useState("");
  const [cSOLDate, setCSOLDate] = useState("");

  function formatDate(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Get the month, day, and year using UTC methods
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = date.getUTCDate().toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    // Return the date in mm/dd/yyyy format
    return `${month}/${day}/${year}`;
  }

  // Monitor clientdbasedata changes
  useEffect(() => {
    if (clientdbasedata) {
      console.log("Updated clientdbasedata:", clientdbasedata);

      // Update local state or perform actions based on clientdbasedata
      setCFileNumber(clientdbasedata.filenumber);
      setCPortfolio(clientdbasedata.portfolio);
      setCDatePlaced(clientdbasedata.dateplaced);
      setCOriginalCreditor(clientdbasedata.originalcreditor);
      setCChargeoffDate(clientdbasedata.datechargedoff);
      setCOriginalBalance(clientdbasedata.originalbalance);
      setCCurrentBalance(clientdbasedata.currentbalance);
      setCCampaignName(clientdbasedata.campaignname);
      setCActive(clientdbasedata.active);
      setCLevel(clientdbasedata.level);
      setCAssignedTo(clientdbasedata.assignedto);
      setCCampaignFee(clientdbasedata.campaignfee);
      setCProductType(clientdbasedata.producttype);
      setCSOLDate(formatDate(clientdbasedata.soldate));
    }
  }, [clientdbasedata]); // This will trigger when clientdbasedata is updated in Redux

  const handleViewClick = (item) => {
    setSifId(item.id);
    setAgencyName(item.agencyname);
    setRequestedBy(item.requestedby);
    setRequestedDate(item.dateofrequest);
    setRequestedSif(item.sifamount);
    setNumberOfPayments(item.sifnumberofpays);
    setStatus(item.status);
    if (item.duedate == null) {
      setDueDate("1900-01-01");
    } else {
      setDueDate(item.duedate.toString());
    }

    setCountered(false);
    setCounterOffer(item.counteroffer);
    setCounterNumberOfPayments(item.counternumberofpays);
    setReason(item.reason);
    console.log(apidata);
    getexternalaccountinfo(item.clientfilenumber, apidata.apikey, apidata.pin);
  };

  // Function to fetch data regardless of statuses
  const fetchAll = () => {
    getsifsuniversal();
  };

  // Function to fetch data regardless of statuses
  const fetchNew = () => {
    getsifs("NEW");
  };

  // Function to fetch data regardless of statuses
  const fetchCompleted = () => {
    getsifsnotnew("NEW");
  };

  //   const onMarkAsDoneClick = (selectedRowData) => {
  //     markasdone(selectedRowData.id);
  //     window.location.reload();
  //   };

  const handleSubmit = () => {
    // Ensure you have the necessary data
    if (sifId === -1) {
      alert("Please select a SIF request before submitting.");
      return;
    }

    // Call the action to update internal data
    updateinternaldata(
      sifId,
      status,
      countered,
      counterNumberOfPayments,
      counterOffer,
      dueDate
    );

    // Optional: Add any other necessary actions or validations here
    console.log("Submitting changes for SIF request:", sifId);

    // Optionally, you can handle UI feedback, such as resetting fields or showing a success message
    alert("Changes submitted successfully");
  };

  return (
    <div className="container mt-3">
      <h2>View Client Requests</h2>
      <div className="btn-group">
        <button
          className="btn btn-success"
          onClick={() => fetchNew()}
          onTouchStart={() => fetchNew()}
        >
          New
        </button>
        <button
          className="btn btn-warning"
          onClick={() => fetchCompleted()}
          onTouchStart={() => fetchCompleted()}
        >
          Completed
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              {/* id,clientname,agencyname,clientfilenumber,agencyfilenumber,currentbalance,dateofrequest,requestedby,sifamount,sifnumberofpays,reason,STATUS,respondedby,respondeddate,counteroffer,counternumberofpays,duedate,emailed  */}
              <th>Actions</th> {/* Add a new column for actions */}
              <th>id</th>
              <th>clientname</th>
              <th>agencyname</th>
              <th>clientfilenumber</th>
              <th>agencyfilenumber</th>
              <th>currentbalance</th>
              <th>dateofrequest</th>
              <th>requestedby</th>
              <th>sifamount</th>
              <th>sifnumberofpays</th>
              <th>reason</th>
              <th>status</th>
              <th>respondedby</th>
              <th>respondeddate</th>
              <th>counteroffer</th>
              <th>counternumberofpays</th>
              <th>duedate</th>
              <th>emailed</th>
            </tr>
          </thead>
          <tbody>
            {sifs.map((item, index) => (
              <tr key={item.id}>
                <td>
                  <button
                    className="btn btn-link"
                    onClick={() => handleViewClick(item)}
                    onTouchStart={() => handleViewClick(item)}
                  >
                    View
                  </button>
                </td>

                <td>{item.id}</td>
                <td>{item.clientname}</td>
                <td>{item.agencyname}</td>
                <td>{item.clientfilenumber}</td>
                <td>{item.agencyfilenumber}</td>
                <td>{item.currentbalance}</td>
                <td>{item.dateofrequest}</td>
                <td>{item.requestedby}</td>
                <td>{item.sifamount}</td>
                <td>{item.sifnumberofpays}</td>
                <td>{item.reason}</td>
                <td>{item.status}</td>
                <td>{item.respondedby}</td>
                <td>{item.respondeddate}</td>
                <td>{item.counteroffer}</td>
                <td>{item.counternumberofpays}</td>
                <td>{item.duedate}</td>
                <td>{item.emailed}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="container mt-3">
        <ol className="list-group">
          <li className="list-group-item">
            <h2>Account Info</h2>
          </li>
          <li className="list-group-item">
            <strong>File Number:</strong> {cFileNumber}
          </li>
          <li className="list-group-item">
            <strong>Portfolio Name:</strong> {cPortFolio}
          </li>
          <li className="list-group-item">
            <strong>Date Placed:</strong> {cDatePlaced}
          </li>
          <li className="list-group-item">
            <strong>Original Creditor:</strong> {cOriginalCreditor}
          </li>
          <li className="list-group-item">
            <strong>Charge off Date:</strong> {cChargeoffDate}
          </li>
          <li className="list-group-item">
            <strong>Original Balance: </strong>
            {cOriginalBalance}
          </li>
          <li className="list-group-item">
            <strong>Current Balance: </strong>
            {cCurrentBalance}
          </li>
          <li className="list-group-item">
            <strong>Campaign Name: </strong>
            {cCampaignName}
          </li>

          <li className="list-group-item">
            <strong>Active: </strong>
            {cActive}
          </li>

          <li className="list-group-item">
            <strong>Level: </strong>
            {cLevel}
          </li>

          <li className="list-group-item">
            <strong>Assigned To: </strong>
            {cAssignedTo}
          </li>

          <li className="list-group-item">
            <strong>Campaign Fee: </strong>
            {cCampaignFee}
          </li>

          <li className="list-group-item">
            <strong>Product Type: </strong>
            {cProductType}
          </li>

          <li className="list-group-item">
            <strong>SOL Date: </strong>
            {cSOLDate}
          </li>
        </ol>
      </div>

      <div className="container mt-3">
        <ol className="list-group">
          <li className="list-group-item">
            <h2>Settlement Request</h2>
          </li>
          <li className="list-group-item">
            <strong>Agency Name:</strong> {agencyName}
          </li>
          <li className="list-group-item">
            <strong>Requested By:</strong> {requestedBy}
          </li>
          <li className="list-group-item">
            <strong>Requested Date:</strong> {requestedDate}
          </li>
          <li className="list-group-item">
            <strong>Requested Sif:</strong> {requestedSif}
          </li>
          <li className="list-group-item">
            <strong>Number of Payment:</strong> {numberOfPayments}
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <strong>Status:</strong>

            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="statusDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {status}
              </button>
              <ul className="dropdown-menu" aria-labelledby="statusDropdown">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setStatus("NEW")}
                  >
                    NEW
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setStatus("ACCEPTED")}
                  >
                    ACCEPTED
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setStatus("COUNTERED")}
                  >
                    COUNTERED
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setStatus("DENIED")}
                  >
                    DENIED
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setStatus("DONE")}
                  >
                    DONE
                  </button>
                </li>
              </ul>
            </div>
          </li>

          <li className="list-group-item">
            <strong>Due Date: </strong>
            <input
              type="date"
              className="form-control"
              placeholder="Enter date"
              name="duedate"
              value={dueDate}
              onChange={(event) => setDueDate(event.target.value)}
            />
          </li>
          <li className="list-group-item">
            <strong>Countered: </strong>
            <input
              className="form-check-input"
              type="checkbox"
              name="remember"
              value={countered}
              onChange={(event) => setCountered(event.target.value)}
            />
          </li>
          <li className="list-group-item">
            <strong>Counter Offer:</strong>
            <input
              type="number"
              className="form-control"
              placeholder="0"
              name="counteroffer"
              value={counterOffer}
              onChange={(event) => setCounterOffer(event.target.value)}
            />
          </li>
          <li className="list-group-item">
            <strong>Counter Number of Payments:</strong>
            <input
              type="number"
              className="form-control"
              placeholder="0"
              name="counterNumberOfPayments"
              value={counterNumberOfPayments}
              onChange={(event) =>
                setCounterNumberOfPayments(event.target.value)
              }
            />
          </li>
          <li className="list-group-item">
            <strong>Reason:</strong>
            <p>{reason}</p>
          </li>
        </ol>
        <div className="d-flex flex-row-reverse">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSubmit}
          >
            Submit Changes
          </button>
        </div>
      </div>
    </div>
  );
};

ClientManager.propTypes = {
  getsifs: PropTypes.func.isRequired,
  getsifsuniversal: PropTypes.func.isRequired,
  getsifsnotnew: PropTypes.func.isRequired,
  markasdone: PropTypes.func.isRequired,
  getexternalaccountinfo: PropTypes.func.isRequired,
  fetch_api_data: PropTypes.func.isRequired,
  updateinternaldata: PropTypes.func.isRequired,
  sif: PropTypes.object.isRequired,
  apidata: PropTypes.object.isRequired,
  clientdbasedata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sif: state.sif,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getsifs,
  getsifsuniversal,
  getsifsnotnew,
  fetch_api_data,
  markasdone,
  getexternalaccountinfo,
  updateinternaldata,
})(ClientManager);
